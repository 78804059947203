header {
    display: block;
    color: white;
    background-color:  #002a37;
    height: 5pc;
}
.title {
    font-size: 20pt;
    font-style: bold;
}
.description {
    font-style: italic;
}
.price {
    font-style: italic;
}
.code {
    font-style: italic;
    font-size: small;
    position: absolute;
    top: 4pc;
    right: 1pc;
}