footer {
    display: relative;
    color: #7c7c7c;
    height: 12pc;
    width: 100%;
}

.amazondisclaimer {
    font-size: small;
    padding: 7px;
}
