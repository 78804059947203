.container {
    position: flex;
    color:#002a37;
}

.date {
    font-size: small;
    font-weight: 500;
    padding: 7px;
    text-align: right;
}

.datechanger {
    padding: 7px;
    text-align: right;
}

.datechangertextfield {
    width: 180px;
}